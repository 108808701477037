::selection {
  background-color: #6dc348;
  color: white;
}
::moz-selection {
  background-color: #6dc348;
  color: white;
}
::webkit-selection {
  background-color: #6dc348;
  color: white;
}
body {
  background-color: #ffffff;
  font-family: Arial;
  color: #353535;
  font-size: 43px;
  font-weight: 700;
  line-height: 53px;
  margin: 0;
}
h1 {
  font-family: Arial;
  color: #353535;
  font-size: 76px;
  font-weight: 700;
  line-height: 80px;
  margin: 0 0 3px 0;
}
#logo {
  position: absolute;
  top: 10%;
  left: 0;
  transform: translate(0, -50%);
  -moz-transform: translate(0, -50%);
  -webkit-transform: translate(0, -50%);
  margin: 0 135px;
}
#logo img {
  height: 50px;
  width: auto;
  max-width: none;
  margin: 0;
}
#container {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(0, -50%);
  -moz-transform: translate(0, -50%);
  -webkit-transform: translate(0, -50%);
  margin: 0 135px;
}
p {
  margin: 0;
}
img {
  margin: 30px 0 0 0;
  max-width: 100%;
}
.button {
  margin: 75px 0 0 0;
  display: inline-block;
  background-color: #6dc348;
  color: #ffffff;
  font-size: 24px;
  font-weight: 700;
  line-height: 28px;
  text-align: left;
  text-shadow: 1px 2px 0px rgba(0, 0, 0, 0.13);
  text-decoration: none;
  padding: 13px 30px;
  outline: none;
  border: none;
  transition: All 0.5s ease;
  -webkit-transition: All 0.5s ease;
  -moz-transition: All 0.5s ease;
  -o-transition: All 0.5s ease;
}
.button:hover {
  background: #427c29;
}
/* MD Medium devices (desktops, 992px and up) */
@media (max-width: 768px) {
  body {
    font-size: 32px;
    line-height: 43px;
  }
  h1 {
    font-size: 52px;
    line-height: 60px;
  }
  #logo {
    margin: 0 30px;
  }
  #logo img {
    height: 40px;
  }
  #container {
    margin: 0 30px;
  }
  .button {
    font-size: 18px;
    line-height: 24px;
  }
}
